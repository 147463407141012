import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { INITIAL_VIEW, Size, ToasterType, View } from "odoconstants";
import { LoginPayload, ToasterProps, User, UserState } from "./types";
import axios from "axios";
import md5 from "util/md5impl";
import { upsertLookup } from "./dataSlice";

enum UserAction {
  CHECK = "users/check-user",
  LOGIN = "users/login",
  LOGOUT = "users/logout",
}

const initialState: UserState = {
  user: null,
  status: "ready",
  view: View.LOGIN,
  loginMsg: "",
  size: Size.SMALL,
  menuExpanded: false,
  toasterId: 0,
  toasters: {},
};

export const checkUser = createAsyncThunk(UserAction.CHECK, async () => {
  const response = await axios.get("/api/check-user.php");
  return response.data;
});

export const login = createAsyncThunk(
  UserAction.LOGIN,
  async ({ username, password }: { username: string; password: string }) => {
    const response = await axios.post("/api/login.php", {
      username,
      password: md5(password),
    });
    console.log(response);
    return response.data;
  }
);

export const logout = createAsyncThunk(UserAction.LOGOUT, async () => {
  const response = await axios.get("/api/logout.php");
  return response.data;
});

const handleUserCheck = (
  state: UserState,
  action: PayloadAction<User | null>
) => {
  state.user = action.payload;
  if (action.payload !== null && state.view === View.LOGIN) {
    state.view = INITIAL_VIEW;
  }
  if (action.payload === null && state.view !== View.LOGIN) {
    state.view = View.LOGIN;
  }
};

const handleLogin = (state: UserState, action: PayloadAction<LoginPayload>) => {
  state.status = "ready";
  if (action.payload.loggedin) {
    state.user = action.payload.user;
    state.view = INITIAL_VIEW;
  } else {
    state.loginMsg = action.payload.message;
  }
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    changeView: (state, action: PayloadAction<View>) => {
      state.view = state.user === null ? View.LOGIN : action.payload;
      state.menuExpanded = false;
    },
    setViewSize: (state, action: PayloadAction<Size>) => {
      state.size = action.payload;
    },
    toggleMenu: (state) => {
      state.menuExpanded = !state.menuExpanded;
    },
    addToaster: (state, action: PayloadAction<ToasterProps>) => {
      state.toasters[state.toasterId++] = action.payload;
    },
    killToaster: (state, action: PayloadAction<number>) => {
      delete state.toasters[action.payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(checkUser.fulfilled, handleUserCheck)
      .addCase(checkUser.rejected, (state) => {
        state.user = null;
        state.view = View.LOGIN;
      })
      .addCase(login.pending, (state) => {
        state.status = "pending";
        state.loginMsg = "";
      })
      .addCase(login.fulfilled, handleLogin)
      .addCase(login.rejected, (state) => {
        state.user = null;
        state.view = View.LOGIN;
      })
      .addCase(logout.pending, (state) => {
        state.user = null;
        state.view = View.LOGIN;
      })
      .addCase(upsertLookup.rejected, (state, action) => {
        state.toasters[state.toasterId++] = {content: `Coś poszło nie tak: ${action.error.message}`, type: ToasterType.ERROR};
      }),
});

export const { changeView, setViewSize, toggleMenu, addToaster, killToaster } =
  userSlice.actions;

export default userSlice.reducer;
