import { useAppDispatch, useAppSelector } from "app/hooks";
import { Reservation } from "app/types";
import { Size } from "odoconstants";
import { DateDisplay } from "components/DateDisplay";
import { BedPartyCells } from "components/BedPartyCells";
import { DisplayDiv } from "components/DisplayDiv";
import { RoomEditor } from "./RoomEditor";
import { useContext, useEffect } from "react";
import { ModalContext } from "components/Modal/ModalContext";
import { ReservationForm } from "components/ReservationForm/ReservationForm";
import { isWithinInterval } from "date-fns";
import styles from "assets/table.module.scss";
import { useConflictFinder } from "./useConflictFinder";
import { Editor } from "components/Editor";
import { changeStatus } from "app/dataSlice";
import { AbsenceBox } from "components/RoomsTable/AbsenceBox";

export const ReservationsRow = ({
  reservation,
}: {
  reservation: Reservation<number>;
}) => {
  const dispatch = useAppDispatch();
  const rooms = useAppSelector((state) => state.data.rooms);
  const size = useAppSelector((state) => state.users.size);
  const modal = useContext(ModalContext);
  const getConflicts = useConflictFinder(reservation.fromdate, reservation.todate, reservation.id);
  const absences = getConflicts.absences();

  useEffect(() => console.log(reservation.id, absences), [absences, reservation.id]);

  const editReservation = () => {
    modal?.open({ component: <ReservationForm reservation={reservation}/> });
  }

  const fromDate = <DateDisplay day={reservation.fromdate} clickCb={editReservation} />;
  const toDate = <DateDisplay day={reservation.todate} clickCb={editReservation} />;

  const roomEditors = Object.values(rooms).map((room) => (
    <RoomEditor roomId={room.id} reservationId={reservation.id} key={room.id} conflicts={getConflicts.forRoom(room.id)} />
  ));

  const reservationIsNow = isWithinInterval(new Date(), {start: reservation.fromdate, end: reservation.todate});

  const handleStatusChange = (s: string) => {
    dispatch(changeStatus({status: s, resId: reservation.id}))
  }

  switch (size) {
    case Size.XLARGE:
    case Size.LARGE:
      return (
        <tr className={reservationIsNow ? styles.ongoing : ''}>
          <td width="45px">
            {absences.map((a) => <AbsenceBox absence={a} key={a.id}/> )}
          </td>
          <td width="45px">
            <Editor value={reservation.status ?? ""} onValueChange={handleStatusChange} />
          </td>
          {size === Size.LARGE && (
            <td>
              {fromDate}
              {toDate}
            </td>
          )}
          {size === Size.XLARGE && (
            <>
              <td>{fromDate}</td>
              <td>{toDate}</td>
            </>
          )}
          <td>
            <DisplayDiv clickCb={editReservation}>{reservation.client}</DisplayDiv>
          </td>
          <BedPartyCells
            countBed={reservation.count_bed}
            countParty={reservation.count_party}
          />
          <td>
            <DisplayDiv>{reservation.source}</DisplayDiv>
          </td>
          <td>
            <DisplayDiv>{reservation.remarks}</DisplayDiv>
          </td>
          {size === Size.LARGE && <td>{roomEditors}</td>}
          {size !== Size.LARGE && roomEditors}
        </tr>
      );
    case Size.MEDIUM:
    case Size.SMALL:
    default:
      return (
        <tr className={reservationIsNow ? styles.ongoing : ''}>
          <td width="45px">?</td>
          <td>
            {fromDate} {toDate}
            <DisplayDiv>{reservation.client}</DisplayDiv>
            {size === Size.SMALL && (
              <DisplayDiv>{reservation.remarks}</DisplayDiv>
            )}
          </td>
          <BedPartyCells
            countBed={reservation.count_bed}
            countParty={reservation.count_party}
          />
          {size === Size.MEDIUM && (
            <td>
              <DisplayDiv>{reservation.remarks}</DisplayDiv>
              <div>{roomEditors}</div>
            </td>
          )}
        </tr>
      );
  }
};
