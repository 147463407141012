import styles from "assets/table.module.scss";
import { ReactNode } from "react";

export const DisplayDiv = ({ children, clickCb }: { children: ReactNode, clickCb?: () => void }) => {
  const clName = clickCb ? styles.displayDivLink : styles.displayDiv;
  return children === "" ? (
    <></>
  ) : (
    <div className={clName} onClick={clickCb}>{children}</div>
  );
};
