import {
  PayloadAction,
  createAsyncThunk,
  createListenerMiddleware,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Absence, Lookup, LookupResponse, RawData, ReservationsState, SortDirection, SortableColumn } from "./types";
import axios from "axios";
import { add, differenceInDays, format, set } from "date-fns";
import { addToaster } from "./usersSlice";
import { ToasterType } from "odoconstants";
import { Reservation } from "./types";

enum ReservationAction {
  GET_ALL = "reservations/get-all",
  UPSERT_LOOKUP = "reservations/upsert_lookup",
  UPSERT_RESERVATION = "reservations/upsert_reservation",
  DELETE_RESERVATION = "reservations/delete_reservation",
  CHANGE_STATUS = "reservations/change_status",
  UPSERT_ABSENCE = "reservations/upsert_absence",
  DELETE_ABSENCE = "reservations/delete_absence",
}

const initialState: ReservationsState = {
  initialized: false,
  pending: false,
  reservations: {},
  rooms: {},
  lookup: {},
  absence: {},
  dates: {},
  mindate: 0,
  maxdate: 0,
  total: 0,
  resIds: [],
  sort: {column: 'fromdate', direction: SortDirection.ASC},
  queryParams: {
    method: "current",
    active: true,
    pageIndex: 0,
    pageSize: 50,
  },
};

export const getAllData = createAsyncThunk(
  ReservationAction.GET_ALL,
  async (queryParams: ReservationsState["queryParams"], thunkApi) => {
    const response = await axios.get(
      `/api/get-data.php?method=${queryParams.method}&active=${queryParams.active}&page=${queryParams.pageIndex}&perPage=${queryParams.pageSize}`
    );
    return response.data;
  }
);

export const upsertLookup = createAsyncThunk(
  ReservationAction.UPSERT_LOOKUP,
  async (lookup: Lookup, thunkApi) => {
    const response = await axios.put("/api/upsert-lookup.php", lookup);
    const data = response.data as LookupResponse;
    if (data.success) {
      thunkApi.dispatch(
        addToaster({ content: "Ilość noclegów w pokoju zmodyfikowana", type: ToasterType.SUCCESS, timeout: 2000 })
      );
    } else {
      thunkApi.dispatch(
        addToaster({ content: "Coś poszło nie tak! " + data.errors, type: ToasterType.ERROR })
      );
    }
    return data;
  }
);

export const changeStatus = createAsyncThunk(
  ReservationAction.CHANGE_STATUS,
  async (data: {status: string, resId: number}, thunkApi) => {
    const response = await axios.put("/api/update-status.php", data);
    const result = response.data as boolean;
    
    if (result) {
      thunkApi.dispatch(
        addToaster({ content: "Status rezerwacji zmodyfikowany", type: ToasterType.SUCCESS, timeout: 2000 })
      );
    } else {
      thunkApi.dispatch(
        addToaster({ content: "Coś poszło nie tak! ", type: ToasterType.ERROR })
      );
    }
    return {...data};
  }
);

export const upsertReservation = createAsyncThunk(
  ReservationAction.UPSERT_RESERVATION,
  async({reservation, lookups}: {reservation: Reservation<number>, lookups: Lookup[]}, thunkApi) => {
    const r = {...reservation, fromdate: format(reservation.fromdate, 'yyyy-MM-dd'), todate: format(reservation.todate, 'yyyy-MM-dd')};
    const response = await axios.post("/api/upsert-reservation.php", {reservation: r, lookups});
    if (response.data.success) {
      thunkApi.dispatch(
        addToaster({ content: "Rezerwacja zapisana", type: ToasterType.SUCCESS, timeout: 2000 })
      );
    } else {
      thunkApi.dispatch(
        addToaster({ content: "Błąd! " + response.data.result, type: ToasterType.ERROR })
      );
    }
    return response.data as {success: boolean, result: {reservation: Reservation<string>, lookup: Lookup[]}};
   }
)

export const upsertAbsence = createAsyncThunk(
  ReservationAction.UPSERT_ABSENCE,
  async(abs: Absence<number>, thunkApi) => {
    const absence = {...abs, fromdate: format(abs.fromdate, 'yyyy-MM-dd'), todate: format(abs.todate, 'yyyy-MM-dd')};
    const response = await axios.post("/api/upsert-absence.php", {absence});
    if (response.data.success) {
      thunkApi.dispatch(
        addToaster({ content: "Nieobecność zapisana", type: ToasterType.SUCCESS, timeout: 2000 })
      );
    } else {
      thunkApi.dispatch(
        addToaster({ content: "Błąd! " + response.data.result, type: ToasterType.ERROR })
      );
    }
    return response.data as {success: boolean, result: Absence<string> | string};
   }
)

export const deleteReservation = createAsyncThunk(
  ReservationAction.DELETE_RESERVATION,
  async(resId: number, thunkApi) => {
    const response = await axios.get("/api/delete-reservation.php?resId=" + resId);
    if (response.data.success) {
      thunkApi.dispatch(
        addToaster({ content: "Rezerwacja usunięta", type: ToasterType.SUCCESS, timeout: 2000 })
      );
    } else {
      thunkApi.dispatch(
        addToaster({ content: "Błąd! " + response.data.result, type: ToasterType.ERROR })
      );
    }
    return response.data as {success: boolean, result: number};
   }
)

export const deleteAbsence = createAsyncThunk(
  ReservationAction.DELETE_ABSENCE,
  async(absId: number, thunkApi) => {
    const response = await axios.get("/api/delete-absence.php?absId=" + absId);
    if (response.data.success) {
      thunkApi.dispatch(
        addToaster({ content: "Nieobecność usunięta", type: ToasterType.SUCCESS, timeout: 2000 })
      );
    } else {
      thunkApi.dispatch(
        addToaster({ content: "Błąd! " + response.data.result, type: ToasterType.ERROR })
      );
    }
    return response.data as {success: boolean, result: number | string };
   }
)

const getSortedReservationIds = (reservations: Reservation<number>[], sort: {column: SortableColumn, direction: SortDirection}) => {
  // od, do, imprezy, noclegi, klient, źródło, uwagi
  const sortFunc = (a: Reservation<number>, b: Reservation<number>) => {
    if (typeof(a[sort.column]) === 'number') {
      const aa = a[sort.column] as number;
      const bb = b[sort.column] as number;
      return aa - bb;
    }
    const aa = a[sort.column] as string;
    const bb = b[sort.column] as string;
    if (aa.toLowerCase() < bb.toLowerCase()) { return -1; }
    if (aa.toLowerCase() > bb.toLowerCase()) { return 1; }
    return 0;
  }
  const ids = reservations.sort(sortFunc).map((r) => r.id);
  if (sort.direction === SortDirection.DESC) { ids.reverse(); }
  return ids;
}

const handleDataLoaded = (
  state: ReservationsState,
  action: PayloadAction<RawData>
) => {
  console.time("prepareData");
  state.initialized = true;
  state.pending = false;
  state.maxdate = set(new Date(action.payload.maxdate), { hours: 0 }).getTime();
  state.mindate = set(new Date(action.payload.mindate), { hours: 0 }).getTime();
  state.reservations = action.payload.reservations.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: {
        ...cur,
        fromdate: set(new Date(cur.fromdate), { hours: 0 }).getTime(),
        todate: set(new Date(cur.todate), { hours: 0 }).getTime(),
      },
    }),
    {}
  );
  state.absence = action.payload.absence.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: {
        ...cur,
        fromdate: set(new Date(cur.fromdate), { hours: 0 }).getTime(),
        todate: set(new Date(cur.todate), { hours: 0 }).getTime(),
      },
    }),
    {}
  );
  state.resIds = getSortedReservationIds(Object.values(state.reservations), state.sort);
  state.rooms = action.payload.rooms.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: {
        ...cur,
        private: cur.private !== 0,
      },
    }),
    {}
  );
  state.lookup = action.payload.lookup.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.res_id + "|" + cur.room_id]: cur,
    }),
    {}
  );

  state.total = action.payload.total;
  createDates(state);
  console.timeEnd("prepareData");
};

const createDates = (state: ReservationsState) => {
  state.dates = {};
  for (const res of Object.values(state.reservations)) {
    const d = differenceInDays(res.todate, res.fromdate);
    for (let i = 0; i < d; i++) {
      const day = add(res.fromdate, { days: i }).getTime();
      const arr = state.dates[day] ?? [];
      state.dates = {
        ...state.dates,
        [day]: [...arr, res.id],
      };
    }
  }
};

export const selectAbsenceDates = createSelector(
  (state: ReservationsState) => state.absence,
  (absence) => Object.values(absence).reduce((acc, cur) => {
    const d = differenceInDays(cur.todate, cur.fromdate);
    for (let i = 0; i < d; i++) {
      const day = add(cur.fromdate, { days: i }).getTime();
      acc[day] = [...acc[day] ?? [], cur.id]
    }
    return acc;
  }, {} as Record<number,number[]>)
)

export const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    setQueryPageSize: (state, action: PayloadAction<number>) => {
      state.queryParams.pageSize = action.payload;
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      state.queryParams.active = action.payload;
      state.queryParams.method = "current";
    },
    setPageIndex: (state, action: PayloadAction<number>) => {
      state.queryParams.method = "past";
      state.queryParams.pageIndex = action.payload;
    },
    setMethodToCurrent: (state) => {
      state.queryParams.method = "current";
    },
    sort: (state, action: PayloadAction<SortableColumn>) => {
      const direction = (state.sort.column === action.payload && state.sort.direction === SortDirection.ASC) ? SortDirection.DESC : SortDirection.ASC;
      const sort = {column: action.payload, direction};
      state.sort = sort;
      state.resIds = getSortedReservationIds(Object.values(state.reservations), sort);
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllData.pending, (state) => {
        state.pending = true;
      })
      .addCase(getAllData.fulfilled, handleDataLoaded)
      .addCase(getAllData.rejected, (state) => {
        state.pending = false;
      })
      .addCase(upsertLookup.fulfilled, (state, action: PayloadAction<LookupResponse>) => {
        if (!action.payload.success) { return; }
        if (action.payload.result.bed_count === '') {
          delete(state.lookup[action.payload.result.res_id + '|' + action.payload.result.room_id]);
        } else {
          state.lookup[action.payload.result.res_id + '|' + action.payload.result.room_id] = action.payload.result;
        }
      })
      .addCase(upsertReservation.pending, (state) => {
        state.pending = true;
      })
      .addCase(upsertReservation.fulfilled, (state, action) => {
        state.pending = false;
        if (!action.payload.success) { return; }
        const resId = action.payload.result.reservation.id;
        for (let k of Object.keys(state.rooms)) {
          delete(state.lookup[resId + '|' + k]);
        }
        for (let l of action.payload.result.lookup) {
          state.lookup[l.res_id + '|' + l.room_id] = l;
        }
        const reservation = {
          ...action.payload.result.reservation,
          fromdate: set(new Date(action.payload.result.reservation.fromdate), { hours: 0 }).getTime(),
          todate: set(new Date(action.payload.result.reservation.todate), { hours: 0 }).getTime(),
        }
        const oldStatus = state.reservations[resId]?.status.length === 0;
        const newStatus = reservation.status.length === 0;
        const statusHasChanged = oldStatus !== newStatus;
        if (statusHasChanged) {
          delete(state.reservations[resId]);
        } else {
          state.reservations[resId] = reservation;
        }
        createDates(state);
      })
      .addCase(upsertReservation.rejected, (state) => {
        state.pending = false;
      })
      .addCase(deleteReservation.pending, (state) => {
        state.pending = true;
      })
      .addCase(deleteReservation.fulfilled, (state, action) => {
        state.pending = false;
        delete (state.reservations[action.payload.result]);
        for (let k of Object.keys(state.rooms)) {
          delete (state.lookup[action.payload.result + '|' + k]);
        }
      })
      .addCase(deleteReservation.rejected, (state) => {
        state.pending = false;
      })
      .addCase(changeStatus.fulfilled, (state, action: PayloadAction<{resId: number, status: string}>) => {
        const active = state.queryParams.active;
        if ((active && action.payload.status.length > 0) || (!active && action.payload.status.length === 0)) {
          delete (state.reservations[action.payload.resId]);
          createDates(state);
        } else {
          state.reservations[action.payload.resId].status = action.payload.status
        }
      })
      .addCase(upsertAbsence.fulfilled, (state, action: PayloadAction<{success: boolean, result: Absence<string> | string}>) => {
          if (action.payload.success) {
            const abs = action.payload.result as Absence<string>;
            const absence = {
              ...state.absence,
              [abs.id]: {
                ...abs,
                fromdate: set(new Date(abs.fromdate), {hours: 0}).getTime(),
                todate: set(new Date(abs.todate), {hours: 0}).getTime(),
              }
            }
            state.absence = absence;
          }
      })
      .addCase(deleteAbsence.fulfilled, (state, action: PayloadAction<{success: boolean, result: number | string}>) => {
        if (action.payload.success) {
          delete(state.absence[action.payload.result as number]);
        } 
      })
});

export const resListenerMiddleware = createListenerMiddleware();

resListenerMiddleware.startListening({
  actionCreator: upsertAbsence.fulfilled,
  effect: (action, api) => {
    console.log("I am listening", action, api);
    console.log(api);
  },
});

export const { setQueryPageSize, setActive, setPageIndex, setMethodToCurrent, sort } =
  reservationsSlice.actions;

export default reservationsSlice.reducer;
