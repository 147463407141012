import { useAppDispatch, useAppSelector } from "app/hooks";
import { toggleMenu } from "app/usersSlice";
import styles from "./style.module.scss";

export const MenuIcon = () => {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector((state) => state.users.menuExpanded);

  return (
    <div
      onClick={() => dispatch(toggleMenu())}
      className={
        expanded
          ? styles.menuIcon + " " + styles.expanded
          : styles.menuIcon + " " + styles.collapsed
      }
    >
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};
