import { useAppDispatch, useAppSelector } from "app/hooks";
import { Editor } from "components/Editor";
import { Size } from "odoconstants";
import styles from "assets/table.module.scss";
import { upsertLookup } from "app/dataSlice";
import { ModalContext } from "components/Modal/ModalContext";
import { useContext } from "react";
import { ConflictWindow } from "components/ConflictWindow/ConflictWindow";

export const RoomEditor = ({
  roomId,
  reservationId,
  conflicts,
}: {
  roomId: number;
  reservationId: number;
  conflicts: number[];
}) => {
  const lookup = useAppSelector(
    (state) => state.data.lookup[reservationId + "|" + roomId]
  );
  const size = useAppSelector((state) => state.users.size);
  const roomName = useAppSelector(
    (state) => state.data.rooms[roomId]?.name ?? ""
  );
  const dispatch = useAppDispatch();
  const modal = useContext(ModalContext);

  const handleChange = (v: string) => {
    if (v !== (lookup?.bed_count ?? "")) {
      dispatch(
        upsertLookup({ res_id: reservationId, room_id: roomId, bed_count: v })
      );
    }
  };

  const showConflictingReservations = () => {
    modal?.open({component: <ConflictWindow resId={reservationId} conflicts={conflicts} /> })
  }

  const editor = (
    <Editor value={lookup?.bed_count ?? ""} onValueChange={handleChange} />
  );

  const conflictsDisplay = conflicts.length === 0 ? <></>
    : <div className={styles.conflict} onClick={showConflictingReservations}>Konflikt</div>

  return size === Size.XLARGE ? (
    <td>{editor}{conflictsDisplay}</td>
  ) : (
    <div className={styles.roomEditor}>
      <div>{roomName}</div>
      {editor}
      {conflictsDisplay}
    </div>
  );
};
