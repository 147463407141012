import { setMethodToCurrent, setPageIndex } from "app/dataSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Dropdown, DropdownDirection } from "components/Dropdown";
import {
  PAGE_COUNT_TO_SHOW_BESIDES_DROPDOWN,
  PAGE_LIMIT_TO_SHOW_DROPDOWN,
  Size,
} from "odoconstants";
import styles from "assets/pagination.module.css";

export const Pagination = () => {
  const dispatch = useAppDispatch();
  const total = useAppSelector((state) => state.data.total);
  const { pageSize, pageIndex, method } = useAppSelector(
    (state) => state.data.queryParams
  );
  const pageCount = Math.ceil(total / pageSize);
  const pages = Object.keys(new Array(pageCount).fill(0)).map((k) =>
    parseInt(k)
  );
  const size = useAppSelector((state) => state.users.size);
  const small = size === Size.SMALL || size === Size.MEDIUM;
  const showDropdown = pageCount > PAGE_LIMIT_TO_SHOW_DROPDOWN;
  const dropdownOptions = showDropdown
    ? pages.map((i) => ({ label: i + 1 + "", value: i }))
    : [];
  const handleDropdown = (v: number) => dispatch(setPageIndex(v));
  const currentPage = method === "past" ? pageIndex : undefined;

  return (
    <div className="pagination">
      {(small || showDropdown) && (
        <Dropdown
          options={dropdownOptions}
          onChangeAction={handleDropdown}
          value={currentPage ? currentPage + 1 : undefined}
          emptyLabel={"Idź do strony"}
          dropdownDirection={DropdownDirection.UP}
          className={styles.paginationDropdown}
        />
      )}
      {!small &&
        pages.map((i) =>
          !showDropdown ||
          i >= pageCount - PAGE_COUNT_TO_SHOW_BESIDES_DROPDOWN ? (
            <span
              key={i}
              className={
                pageIndex === i && method === "past"
                  ? styles.paginationLink + " " + styles.active
                  : styles.paginationLink
              }
              onClick={() => dispatch(setPageIndex(i))}
            >
              {i + 1}
            </span>
          ) : (
            ""
          )
        )}
      <span
        className={
          method === "current"
            ? styles.paginationLink + " " + styles.active
            : styles.paginationLink
        }
        onClick={() => dispatch(setMethodToCurrent())}
      >
        Najnowsze
      </span>
    </div>
  );
};
