import { useAppSelector } from "app/hooks";
import { ReservationBox } from "./ReservationBox";
import { Size } from "odoconstants";
import styles from "assets/table.module.scss";

export const ReservationBoxes = ({
  day,
  roomId,
}: {
  day: number;
  roomId: number;
}) => {
  const lookup = useAppSelector((state) => state.data.lookup);
  const reservations = useAppSelector((state) => state.data.dates[day] ?? []);
  const roomName = useAppSelector((state) => state.data.rooms[roomId].name);
  const size = useAppSelector((state) => state.users.size);
  const boxCount = reservations.reduce(
    (acc, cur) => (lookup[cur + "|" + roomId] !== undefined ? acc + 1 : acc),
    0
  );
  const boxes = reservations.map((reservation) => (
    <ReservationBox
      reservationId={reservation}
      roomId={roomId}
      key={reservation}
      hasConflict={boxCount > 1}
    />
  ));

  return size === Size.XLARGE || size === Size.LARGE ? (
    <>{boxes}</>
  ) : boxCount > 0 ? (
    <div className={styles.roomBox}>
      <div>{boxCount} {roomName}</div> {boxes}
    </div>
  ) : (
    <></>
  );
};
