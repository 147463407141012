import { useAppDispatch, useAppSelector } from "app/hooks";
import { changeView, logout } from "app/usersSlice";
import { Size, View } from "odoconstants";
import logo from "assets/lion.png";
import { MenuIcon } from "./MenuIcon";
import styles from "./style.module.scss";
import { useContext } from "react";
import { ModalContext } from "components/Modal/ModalContext";
import { ReservationForm } from "components/ReservationForm/ReservationForm";
import { AbsenceForm } from "components/AbsenceForm/AbsenceForm";

export const MainHeader = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => state.users.view);
  const size = useAppSelector((state) => state.users.size);
  const small = size === Size.SMALL || size === Size.MEDIUM;
  const expanded = useAppSelector((state) => state.users.menuExpanded);
  const modal = useContext(ModalContext);
  const stateDebug = useAppSelector((state) => state.data)

  function addReservation() {
    modal?.open({ component: <ReservationForm /> });
  }

  function addAbsence() {
    modal?.open({ component: <AbsenceForm /> });
  }

  return (
    <header className={styles.mainHeader}>
      <div>
        <img src={logo} alt="OdoLogo" width={60} />
        {small && <MenuIcon />}
        {(!small || expanded) && (
          <nav className={small && expanded ? styles.expanded : ""}>
            <button
              onClick={() => dispatch(changeView(View.RESERVATIONS))}
              className={view === View.RESERVATIONS ? styles.active : ""}
            >
              Rezerwacje
            </button>
            <button
              onClick={() => dispatch(changeView(View.ROOMS))}
              className={view === View.ROOMS ? styles.active : ""}
            >
              Pokoje
            </button>
            <button onClick={() => dispatch(logout())}>Wyloguj się</button>
          </nav>
        )}
      </div>
      <div className={small ? styles.small : ""}>
        <div onClick={() => { console.log(stateDebug); }}>online status</div>
        <div className={styles.actionBtns}>
          <button onClick={addAbsence}>{small ? "+" : "Dodaj Nieobecność"}</button>
          <button onClick={addReservation}>
            {small ? "+" : "Dodaj Rezerwację"}
          </button>
        </div>
      </div>
    </header>
  );
};
