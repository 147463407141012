import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { checkUser, setViewSize } from "./app/usersSlice";
import { Size, View } from "./odoconstants";
import { LoginPage } from "./pages/LoginPage";
import { MainHeader } from "./components/main/MainHeader";
import { MainFooter } from "./components/main/MainFooter";
import { ReservationsPage } from "./pages/ReservationsPage";
import { RoomsPage } from "./pages/RoomsPage";
import { getAllData } from "./app/dataSlice";
import { Modal } from "components/Modal/Modal";
import { ModalContext, useModalContext } from "components/Modal/ModalContext";
import { ToasterContainer } from "components/Toaster/ToasterContainer";
import { SpinnerScreen } from "components/SpinnerScreen/SpinnerScreen";

function App() {
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => state.users.view);
  const user = useAppSelector((state) => state.users.user);
  const queryParams = useAppSelector((state) => state.data.queryParams);
  const modalContext = useModalContext();

  const readViewSize = useCallback(() => {
    const w = document.body.clientWidth;
    let size = Size.SMALL;
    if (w > 500) {
      size = Size.MEDIUM;
    }
    if (w > 800) {
      size = Size.LARGE;
    }
    if (w > 1200) {
      size = Size.XLARGE;
    }
    dispatch(setViewSize(size));
  }, [dispatch]);

  useEffect(() => {
    if (user?.id !== undefined) {
      dispatch(getAllData(queryParams));
    }
  }, [queryParams, user?.id, dispatch]);

  useEffect(() => {
    dispatch(checkUser());
    const userTimer = setInterval(() => dispatch(checkUser()), 8000);
    return () => clearInterval(userTimer);
  }, [dispatch]);

  useEffect(() => {
    readViewSize();
    const updateViewSize = (function () {
      let timer: ReturnType<typeof setTimeout>;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(readViewSize, 50);
      };
    })();
    window.addEventListener("resize", updateViewSize);
    return () => window.removeEventListener("resize", updateViewSize);
  }, [readViewSize]);

  const renderView = useCallback(() => {
    switch (view) {
      case View.ROOMS:
        return <RoomsPage />;
      case View.RESERVATIONS:
      default:
        return <ReservationsPage />;
    }
  }, [view]);

  const renderMainFrame = useCallback(() => {
    return (
      <ModalContext.Provider value={modalContext}>
        <div className="fullHeight">
          <MainHeader />
          <main>{renderView()}</main>
          <MainFooter />
        </div>
        <Modal />
        <ToasterContainer />
        <SpinnerScreen/>
      </ModalContext.Provider>
    );
  }, [renderView, modalContext]);

  return user === null ? <LoginPage /> : renderMainFrame();
}

export default App;
