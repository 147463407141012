import { HTMLProps, useCallback, useEffect, useRef, useState } from "react";
import styles from "assets/dropdown.module.css";

export enum DropdownDirection {
  UP = "up",
  DOWN = "down",
}

export type DropdownOption<T> = {
  label: string;
  value: T;
};

export type DropdownProps<T> = HTMLProps<HTMLDivElement> & {
  options: DropdownOption<T>[];
  onChangeAction: (v: T) => void;
  emptyLabel?: string;
  value?: T;
  dropdownDirection?: DropdownDirection;
};

export const Dropdown = <T,>({
  options,
  onChangeAction,
  className,
  value,
  emptyLabel,
  dropdownDirection,
  ...others
}: DropdownProps<T>) => {
  const [expanded, setExpanded] = useState(false);
  const dropdownListRef = useRef<HTMLDivElement>(null);
  const direction = dropdownDirection ?? DropdownDirection.DOWN;
  const dropdownClass = styles.dropdown + " " + styles[direction];

  const toggleExpanded = (evt: React.MouseEvent) => {
    setExpanded(!expanded);
    evt.stopPropagation();
  };

  const handleClickOutside = useCallback(
    (evt: MouseEvent) => {
      const parent = (evt.target as HTMLElement).parentNode;
      if (parent !== dropdownListRef.current) {
        setExpanded(false);
      }
    },
    [dropdownListRef]
  );

  const handleCloseOnEscape = useCallback((evt: KeyboardEvent) => {
    if (evt.key === "Escape") {
      setExpanded(false);
    }
  }, []);

  useEffect(() => {
    if (expanded) {
      window.addEventListener("click", handleClickOutside);
      window.addEventListener("keydown", handleCloseOnEscape);
    } else {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleCloseOnEscape);
    }
  }, [expanded, handleClickOutside, handleCloseOnEscape]);

  const handleChange = (v: T) => () => {
    onChangeAction(v);
    setExpanded(false);
  };

  return (
    <div className={dropdownClass + " " + className} {...others}>
      <div className={styles.display} onClick={toggleExpanded}>
        {value !== undefined && value} {value === undefined && emptyLabel}
        <div className={styles.arrow}></div>
      </div>
      {expanded && (
        <div className={styles.sub} ref={dropdownListRef}>
          {options.map((o) => (
            <div key={o.label} onClick={handleChange(o.value)}>
              {o.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
