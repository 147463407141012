import { useEffect, useState } from "react";
import styles from "assets/switch.module.css";

export const SwitchController = ({
  flag,
  changeAction,
}: {
  flag: boolean;
  changeAction: (v: boolean) => void;
}) => {
  const [internalFlag, setInternalFlag] = useState(flag);

  useEffect(() => {
    changeAction(internalFlag);
  }, [internalFlag, changeAction]);

  return (
    <div
      className={
        internalFlag
          ? styles.switch + " " + styles.on
          : styles.switch + " " + styles.off
      }
      onClick={() => setInternalFlag(!internalFlag)}
    >
      <div></div>
    </div>
  );
};
