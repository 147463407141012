import { ToasterProps } from "app/types";
import styles from "./toasters.module.scss";
import { useAppDispatch } from "app/hooks";
import { killToaster } from "app/usersSlice";
import { useCallback, useEffect, useRef, useState } from "react";

const STEP = 10;

export const Toaster = ({
  content,
  timeout,
  type,
  toasterId,
}: ToasterProps & { toasterId: number }) => {
  const dispatch = useAppDispatch();
  const [progress, setProgress] = useState(timeout ?? 2000);
  const timer = useRef<ReturnType<typeof setInterval> | null>();

  const close = useCallback(() => {
    dispatch(killToaster(toasterId));
    if (timer.current) {
      clearInterval(timer.current);
    }
  }, [dispatch, toasterId]);

  useEffect(() => {
    if (timeout) {
      timer.current = setInterval(() => {
        if (progress <= 0) {
          close();
        }
        setProgress((p) => p - STEP)
      }, STEP);
    }
    return () => {
      if (timer.current !== null) {
        clearInterval(timer.current);
      }
    }
  });

  const width = useCallback(() => {
    if (!timeout || timeout === 0) { return 0; }
    const result = Math.round((progress * 100000) / timeout) / 1000;
    return result;
  }, [progress, timeout])

  return (
    <div className={styles.toaster + " " + styles[type ?? "info"]}>
      <button onClick={close} className={styles.closeButton}>
        X
      </button>
      {content}
      {timeout && <div
        className={styles.progressBar}
        style={{width: width() + '%'}}></div> }
    </div>
  );
};
