import { useRef } from "react";

export const Editor = ({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange: (v: string) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (value !== evt.target.value.trim()) {
      onValueChange(evt.target.value.trim());
    }
  };

  const handleKey = (evt: React.KeyboardEvent) => {
    if (evt.key === "Enter" || evt.key === "Escape") {
      if (ref.current !== null) {
        ref.current.blur();
      }
    }
  };

  return (
    <input
      type="text"
      ref={ref}
      defaultValue={value}
      style={{ width: "26px" }}
      onBlur={handleBlur}
      onKeyDown={handleKey}
    />
  );
};
