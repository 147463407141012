import { useAppSelector } from "app/hooks";
import { RoomsHeader } from "./RoomsHeader";
import styles from "assets/table.module.scss";
import { eachDayOfInterval } from "date-fns";
import { DateRow } from "./DateRow";

export const RoomsTable = () => {
  const interval = useAppSelector((state) => ({
    start: state.data.mindate,
    end: state.data.maxdate,
  }));
  return (
    <table className={styles.table}>
      <RoomsHeader />
      <tbody>
        {eachDayOfInterval(interval).map((i) => (
          <DateRow key={i.getTime()} day={i.getTime()} />
        ))}
      </tbody>
    </table>
  );
};
