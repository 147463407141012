import { useState } from "react";
import styles from "./calendar.module.scss";
import { CalView } from "./utils";
import { Month } from "./Month";
import { Year } from "./Year";
import { Decade } from "./Decade";

type CalendarProps = {
  initDate: number;
  onDateClicked: (val: number) => void;
};

export const Calendar = ({ initDate, onDateClicked }: CalendarProps) => {
  const [view, setView] = useState(CalView.MONTH);
  const [currentDate, setCurrentDate] = useState(initDate);

  const changeView = (v: CalView, evt: React.MouseEvent) => {
    setView(v);
    evt.stopPropagation();
  };

  const renderView = () => {
    switch (view) {
      case CalView.YEAR:
        return (
          <Year
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            changeView={changeView}
          />
        );
      case CalView.DECADE:
        return (
          <Decade
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            changeView={changeView}
          />
        );
      case CalView.MONTH:
      default:
        return (
          <Month
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            onDateClicked={onDateClicked}
            changeView={changeView}
          />
        );
    }
  };

  return <div className={styles.calendarContainer}>{renderView()}</div>;
};
