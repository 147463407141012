import { useAppSelector } from "app/hooks";
import { Lookup } from "app/types";
import styles from "assets/resform.module.scss";
import { Editor } from "components/Editor";

export const LookupEditor = ({roomKey, lookups, setLookups, conflicts}: {
		roomKey: number,
		lookups: Record<string, Lookup>,
		setLookups: React.Dispatch<React.SetStateAction<Record<string, Lookup>>>,
		conflicts: number[]
	}) => {
	const rooms = useAppSelector((state) => state.data.rooms);
	const reservations = useAppSelector((state) => state.data.reservations);
	const room = rooms[roomKey];
	const bedCount = lookups[roomKey].bed_count;
	const classNames = [styles.roomLookup];
	if (room.private) { classNames.push(styles.private); }
	if (conflicts.length > 0) { classNames.push(styles.conflict); }
	const onChange = (v: string) => {
		setLookups((ls) => ({
			...ls,
			[roomKey]: {
				...ls[roomKey],
				bed_count: v,
			}
		}))
	}
	return <div className={classNames.join(' ')}>{room.name}
		<Editor value={bedCount} onValueChange={onChange}/>
		{conflicts.map((c) => <div key={c}>{reservations[c].client}</div>)}
	</div>
}