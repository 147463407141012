import { useId, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { login } from "app/usersSlice";
import logo from "assets/lion.png";
import styles from "./loginPage.module.scss";

export const LoginPage = () => {
  const id = useId();
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const msg = useAppSelector((state) => state.users.loginMsg);

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("logging in ", username, password);
    dispatch(login({ username, password }));
  };

  return (
    <div className="fullHeight">
      <div className={styles.loginBox}>
        <img src={logo} alt="OdoLogo" />
        <form>
          <div>{msg}</div>
          <div className={styles.group}>
            <label htmlFor={id + "_user"}>Użytkownik</label>
            <input
              id={id + "_user"}
              type="text"
              value={username}
              placeholder="Użytkownik lub email"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className={styles.group}>
            <label htmlFor={id + "_pass"}>Hasło</label>
            <input
              id={id + "_pass"}
              type="password"
              value={password}
              placeholder="Twoje hasło"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button onClick={handleSubmit}>Zaloguj się</button>
        </form>
      </div>
    </div>
  );
};
