export enum CalView {
  MONTH,
  YEAR,
  DECADE,
}

export const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];
export const weekDays = ["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"];
