import { SetStateAction } from "react";
import { CalView } from "./utils";
import styles from "./calendar.module.scss";
import { set } from "date-fns";

type DecadeProps = {
  currentDate: number;
  setCurrentDate: React.Dispatch<SetStateAction<number>>;
  changeView: (v: CalView, evt: React.MouseEvent) => void;
};

export const Decade = ({
  currentDate,
  setCurrentDate,
  changeView,
}: DecadeProps) => {
  const years = Object.keys(Array(20).fill(0)).map((k) => 2016 + parseInt(k));

  const handleClick = (y: number) => (evt: React.MouseEvent) => {
    setCurrentDate(set(currentDate, { year: y }).getTime());
    changeView(CalView.YEAR, evt);
  };

  return (
    <>
      <div className={styles.calendarHeaderDecade}>
        <div className={styles.headerInner}>
          <div>2016 - 2035</div>
        </div>
      </div>
      <div className={styles.decadeView}>
        {years.map((y) => (
          <div onClick={handleClick(y)} key={y}>
            {y}
          </div>
        ))}
      </div>
    </>
  );
};
