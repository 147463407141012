import styles from "assets/table.module.scss";
export const BedPartyCells = ({
  countBed,
  countParty,
}: {
  countBed: number;
  countParty: number;
}) => {
  return (
    <>
      <td width="45px">
        {countBed > 0 && <div className={styles.bedCount}>{countBed}</div>}
      </td>
      <td width="45px">
        {countParty > 0 && (
          <div className={styles.partyCount}>{countParty}</div>
        )}
      </td>
    </>
  );
};
