import { ReactNode } from "react";
import { Size, ToasterType, View } from "../odoconstants";

export interface ReservationsState {
  initialized: boolean;
  pending: boolean;
  reservations: Record<number, Reservation<number>>;
  rooms: Record<number, Room<boolean>>;
  lookup: Record<string, Lookup>;
  absence: Record<number, Absence<number>>;
  dates: Record<number, number[]>;
  mindate: number;
  maxdate: number;
  total: number;
  resIds: number[];
  sort: {column: SortableColumn, direction: SortDirection};
  queryParams: {
    method: "current" | "past";
    active: boolean;
    pageIndex: number;
    pageSize: number;
  };
}

export interface UserState {
  user: User | null;
  status: "pending" | "ready";
  view: View;
  loginMsg: string;
  size: Size;
  menuExpanded: boolean;
  toasterId: number;
  toasters: Record<number, ToasterProps>;
}

export interface Absence<T extends string | number> {
  fromdate: T;
  id: number;
  todate: T;
  who: string;
  why: string;
}

export interface Room<T extends boolean | 0 | 1> {
  id: number;
  max_beds: number;
  name: string;
  private: T;
  region: string;
}

export interface Reservation<T extends string | number> {
  client: string;
  count_bed: number;
  count_party: number;
  fromdate: T;
  id: number;
  remarks: string;
  source: string;
  status: string;
  todate: T;
}

export interface Lookup {
  res_id: number;
  room_id: number;
  bed_count: string;
}

export interface RawData {
  debug: string;
  lookup: Lookup[];
  maxdate: string;
  mindate: string;
  reservations: Reservation<string>[];
  rooms: Room<0 | 1>[];
  total: number;
  absence: Absence<string>[];
}

export interface User {
  email: string;
  id: number;
  online: string;
  username: string;
}

interface FailedLoginPayload {
  loggedin: false;
  message: string;
}

interface SuccessfulLoginPayload {
  loggedin: true;
  user: User;
}

export interface ToasterProps {
  content: ReactNode;
  timeout?: number;
  type?: ToasterType;
}

export type LoginPayload = FailedLoginPayload | SuccessfulLoginPayload;


export type LookupResponse = {
  success: false;
  errors: string;
} | {
  success: true;
  result: Lookup;
}

export enum SortDirection {
  ASC, DESC
}

export type SortableNumberColumn = 'fromdate' | 'todate' | 'count_bed' | 'count_party';
export type SortableStringColumn = 'client' | 'source' | 'remarks';
export type SortableColumn = SortableNumberColumn | SortableStringColumn;