import { useContext, useEffect, useId, useState } from "react";
import {
  add,
  differenceInCalendarDays,
  startOfTomorrow,
} from "date-fns";
import { Lookup, Reservation } from "app/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Size } from "odoconstants";
import styles from "assets/resform.module.scss";
import { CalendarInput } from "components/CalendarInput";
import { deleteReservation, upsertReservation } from "app/dataSlice";
import { LookupEditor } from "./LookupEditor";
import { ModalContext } from "components/Modal/ModalContext";
import { useConflictFinder } from "components/ReservationsTable/useConflictFinder";

type ResFormProps = { reservation?: Reservation<number> };

const newReservation = () => ({
  client: "",
  count_bed: 0,
  count_party: 0,
  fromdate: startOfTomorrow().getTime(),
  id: -1,
  remarks: "",
  source: "",
  status: "",
  todate: add(startOfTomorrow(), {days: 7}).getTime(),
});

export const ReservationForm = ({ reservation }: ResFormProps) => {
  const dispatch = useAppDispatch();
  const size = useAppSelector((state) => state.users.size);
  const rooms = useAppSelector((state) => state.data.rooms);
  const lookupData = useAppSelector((state) => state.data.lookup);
  const sizeClass =
    size === Size.SMALL || size === Size.MEDIUM ? "small" : "big";
  const [title] = useState(
    reservation
      ? `Edytuj rezerwację "${reservation.client}"`
      : "Dodaj rezerwację"
  );
  const [res, setRes] = useState(reservation ? reservation : newReservation());
  const getConflicts = useConflictFinder(res.fromdate, res.todate, res.id);
  const [delta, setDelta] = useState(
    differenceInCalendarDays(res.todate, res.fromdate)
  );
  const [deltaDisplay, setDeltaDisplay] = useState(delta + "");
  const [lookups, setLookups] = useState(Object.values(rooms).reduce((acc, room) => ({
    ...acc,
    [room.id]: {
      res_id: res.id,
      room_id: room.id,
      bed_count: lookupData[res.id + '|' + room.id]?.bed_count
    }
  }), {} as Record<string, Lookup>));
  const id = useId();
  const modal = useContext(ModalContext);
  const [allowDelete, setAllowDelete] = useState(false); 

  const setCount =
    (parameter: "count_bed" | "count_party") =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const parsed = parseInt(evt.target.value);
      const val = evt.target.value === "" ? 0 : !isNaN(parsed) ? parsed : res.count_bed;
      setRes((r) => ({ ...r, [parameter]: val }));
    };

  const handleRadio =
    (value: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      setRes((r) => ({ ...r, source: value }));
    };

  const handleDateChange = (param: "fromdate" | "todate") => (val: number) => {
    const start = param === "fromdate" ? val : res.fromdate;
    const end = param === "todate" ? val : res.todate;
    if (end > start) {
      setRes((r) => ({ ...r, [param]: val }));
      setDelta(differenceInCalendarDays(end, start));
      return;
    }
    if (param === "fromdate") {
      const newEndDate = add(val, { days: delta }).getTime();
      setRes((r) => ({ ...r, [param]: val, todate: newEndDate }));
    }
    if (param === "todate") {
      const newStartDate = add(val, { days: -delta }).getTime();
      setRes((r) => ({ ...r, [param]: val, fromdate: newStartDate }));
    }
  };

  useEffect(() => setDeltaDisplay(delta + ""), [delta]);

  const handleDayCount = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const input = parseInt(evt.target.value);
    setDeltaDisplay(evt.target.value);
    if (!isNaN(input) && input > 0) {
      const newEndDate = add(res.fromdate, { days: input }).getTime();
      setDelta(input);
      setRes((r) => ({ ...r, todate: newEndDate }));
    }
  };

  const handleDayCountBlur = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const input = parseInt(deltaDisplay);
    if (isNaN(input)) {
      setDeltaDisplay(delta + "");
    }
  };

  const save = () => {
    const loo = Object.values(lookups).filter((l) => l.bed_count !== undefined);
    dispatch(upsertReservation({reservation: res, lookups: loo}));
    modal?.close();
  };

  const remove = () => {
    if (!allowDelete) {
      setAllowDelete(true);
    } else {
      dispatch(deleteReservation(res.id));
      modal?.close();
    }
  };

  return (
    <>
      <div className={styles.formHeader}>{title}</div>
      <div className={styles.formBody + " " + styles[sizeClass]}>
        <div className={styles.formContainer}>
          <div>
            <label htmlFor={id + "fromdate"}>Od</label>
            <CalendarInput
              id={id + "fromdate"}
              value={res.fromdate}
              onChange={handleDateChange("fromdate")}
            />
          </div>
          <div>
            <label htmlFor={id + "todate"}>Do</label>
            <CalendarInput
              id={id + "todate"}
              value={res.todate}
              onChange={handleDateChange("todate")}
            />
          </div>
          <div>
            <label htmlFor={id + "howlong"}>Ile dni</label>
            <input
              type="text"
              id={id + "howlong"}
              value={deltaDisplay}
              onChange={handleDayCount}
              onBlur={handleDayCountBlur}
            />
          </div>
          <div>
            <label htmlFor={id + "client"}>Osoba Rezerwująca</label>
            <input
              type="text"
              id={id + "client"}
              value={res.client}
              onChange={(evt) =>
                setRes((r) => ({
                  ...r,
                  client: evt.target.value,
                }))
              }
            />
          </div>
          <div>
            <label htmlFor={id + "count_bed"}>Ilość osób na nocleg</label>
            <input
              type="text"
              id={id + "count_bed"}
              value={res.count_bed !== 0 ? res.count_bed : ""}
              onChange={setCount("count_bed")}
            />
          </div>
          <div>
            <label htmlFor={id + "count_party"}>Ilość osób na imprezę</label>
            <input
              type="text"
              id={id + "count_party"}
              value={res.count_party !== 0 ? res.count_party : ""}
              onChange={setCount("count_party")}
            />
          </div>
          <div>
            <span className={styles.label}>Źródło</span>
            <input
              type="radio"
              id={id + "source_tel"}
              name="source"
              checked={res.source === "telefon"}
              onChange={handleRadio("telefon")}
            />
            <label htmlFor={id + "source_tel"}>Telefon</label>
            <input
              type="radio"
              id={id + "source_book"}
              name="source"
              checked={res.source === "booking"}
              onChange={handleRadio("booking")}
            />
            <label htmlFor={id + "source_book"}>Booking</label>
          </div>
          <div>
            <span className={styles.label}></span>
            <input
              type="text"
              id={id + "src"}
              value={res.source}
              onChange={(evt) =>
                setRes((r) => ({ ...r, source: evt.target.value }))
              }
            />
          </div>
          <div>
            <label htmlFor={id + "status"}>Status</label>
            <input
              type="text"
              id={id + "status"}
              value={res.status}
              onChange={(evt) =>
                setRes((r) => ({ ...r, status: evt.target.value }))
              }
            />
          </div>
          <div>
            <label htmlFor={id + "remarks"}>Uwagi</label>
            <textarea
              className={styles.remarksArea}
              id={id + "remarks"}
              onChange={(evt) =>
                setRes((r) => ({ ...r, remarks: evt.target.value }))
              }
              value={res.remarks}
            />
          </div>
          <div className={styles.roomLookups}>
              {Object.keys(rooms).map((roomKey) => (<LookupEditor key={roomKey} roomKey={parseInt(roomKey)} lookups={lookups} setLookups={setLookups} conflicts={getConflicts.forRoom(parseInt(roomKey), reservation === undefined)}/>))}
          </div>
          <div>
          {reservation && <button className={styles.deleteButton} onClick={remove}>
              {!allowDelete ? 'Usuń' : 'Ale na pewno usunąć?'}
            </button>}
            <button className={styles.applyButton} onClick={save}>
              Zapisz Rezerwację
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
