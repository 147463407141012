import { format, isWeekend } from "date-fns";
import styles from "assets/table.module.scss";
import { DATE_FORMAT } from "odoconstants";

export const DateDisplay = ({
  day,
  clickCb,
}: {
  day: number | Date;
  clickCb?: (evt: React.MouseEvent) => void;
}) => {
  let clname = isWeekend(day) ? styles.weekend : styles.weekday;
  if (clickCb) {
    clname += " " + styles.pointer;
  }
  return (
    <div
      className={clname}
      onClick={(evt) => {
        if (clickCb) {
          clickCb(evt);
        }
      }}
    >
      {format(day, DATE_FORMAT)}
    </div>
  );
};
