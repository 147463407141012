import { createContext, Reducer, useReducer } from "react";

type ModalState = {
  isOpen: boolean;
  component: React.ReactNode;
};

const initialState: ModalState = {
  isOpen: false,
  component: null,
};

type ModalOpenAction = {
  type: "OPEN";
  payload: {
    component: React.ReactNode;
  };
};

type ModalCloseAction = {
  type: "CLOSE";
};

type ModalAction = ModalOpenAction | ModalCloseAction;

const reducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case "OPEN":
      return {
        ...state,
        component: action.payload.component,
        isOpen: true,
      };
    case "CLOSE":
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export function useModalContext() {
  const [state, dispatch] = useReducer<Reducer<ModalState, ModalAction>>(
    reducer,
    initialState
  );
  return {
    open: (payload: ModalOpenAction["payload"]) =>
      dispatch({ type: "OPEN", payload }),
    close: () => dispatch({ type: "CLOSE" }),
    state,
  };
}

export const ModalContext = createContext<ReturnType<
  typeof useModalContext
> | null>(null);
