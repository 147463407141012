import { setActive, setQueryPageSize } from "app/dataSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useCallback, useState } from "react";
import styles from "./style.module.scss";
import { Pagination } from "./Pagination";
import { SwitchController } from "components/SwitchController";

export const MainFooter = () => {
  const dispatch = useAppDispatch();
  const pageSize = useAppSelector((state) => state.data.queryParams.pageSize);
  const active = useAppSelector((state) => state.data.queryParams.active);
  const total = useAppSelector((state) => state.data.total);
  const [pgSize, setPgSize] = useState(pageSize);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "Enter" && !isNaN(pgSize)) {
      dispatch(setQueryPageSize(pgSize));
    }
  };

  const toggleArchiveAction = useCallback((v: boolean) => {dispatch(setActive(!v));}, [dispatch]);

  return (
    <footer className={styles.mainFooter}>
      <div>
        <label htmlFor="perPageInput">Na stronie</label>
        <input
          type="number"
          id="perPageInput"
          value={pgSize}
          onChange={(e) => setPgSize(parseInt(e.target.value))}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            if (!isNaN(pgSize)) {
              dispatch(setQueryPageSize(pgSize));
            }
          }}
          className={styles.perPageInput}
        />
        <span>/ {total}</span>
      </div>
      <Pagination />
      <div>
        <SwitchController
          flag={!active}
          changeAction={toggleArchiveAction}
        />{" "}
        Archiwalne Rezerwacje
      </div>
    </footer>
  );
};
