import { SetStateAction, useMemo } from "react";
import { CalView, months, weekDays } from "./utils";
import {
  add,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getMonth,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import styles from "./calendar.module.scss";

type MonthProps = {
  currentDate: number;
  setCurrentDate: React.Dispatch<SetStateAction<number>>;
  changeView: (v: CalView, evt: React.MouseEvent) => void;
  onDateClicked: (val: number) => void;
};

export const Month = ({
  currentDate,
  setCurrentDate,
  changeView,
  onDateClicked,
}: MonthProps) => {
  const [month, weeks] = useMemo(() => {
    const m = months[getMonth(currentDate)];
    const mStart = startOfMonth(currentDate);
    const mEnd = endOfMonth(currentDate);
    const result = eachWeekOfInterval(
      {
        start: startOfWeek(mStart, { weekStartsOn: 1 }),
        end: endOfWeek(mEnd, { weekStartsOn: 1 }),
      },
      { weekStartsOn: 1 }
    ).reduce(
      (acc, cur, i) => ({
        ...acc,
        [i]: eachDayOfInterval({
          start: cur,
          end: endOfWeek(cur, { weekStartsOn: 1 }),
        }),
      }),
      {}
    );
    return [m, result];
  }, [currentDate]);

  const swipeMonth = (amount: -1 | 1) => () => {
    setCurrentDate((d) => add(d, { months: amount }).getTime());
  };

  const handleDateChange = (v: number) => (evt: React.MouseEvent) => {
    onDateClicked(v);
  };

  return (
    <>
      <div className={styles.calendarHeader}>
        <div onClick={swipeMonth(-1)} className={styles.arrow}>
          &lt;
        </div>
        <div className={styles.headerInner}>
          <div
            onClick={(evt) => {
              changeView(CalView.DECADE, evt);
            }}
          >
            {format(currentDate, "yyyy")}
          </div>
          <div
            onClick={(evt) => {
              changeView(CalView.YEAR, evt);
            }}
          >
            {month}
          </div>
        </div>
        <div onClick={swipeMonth(1)} className={styles.arrow}>
          &gt;
        </div>
      </div>
      <div>
        <div className={styles.weekRow}>
          {weekDays.map((d) => (
            <div key={d}>{d}</div>
          ))}
        </div>
        {Object.keys(weeks).map((week) => (
          <div key={week} className={styles.weekRow}>
            {(weeks[week as keyof typeof weeks] as Array<Date>).map((day) => (
              <div
                key={day.getTime()}
                onClick={handleDateChange(day.getTime())}
              >
                {day.getDate()}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
