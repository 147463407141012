export enum View {
  LOGIN,
  RESERVATIONS,
  ROOMS,
}

export enum Size {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
}

export enum ToasterType {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
}

export const INITIAL_VIEW = View.RESERVATIONS;

export const PAGE_LIMIT_TO_SHOW_DROPDOWN = 10;
export const PAGE_COUNT_TO_SHOW_BESIDES_DROPDOWN = 4;

export const DATE_FORMAT = "yyyy-MM-dd";
