import { useAppSelector } from "app/hooks";
import { Size } from "odoconstants";
import { useMemo } from "react";
import styles from "assets/table.module.scss";
import { DateDisplay } from "components/DateDisplay";
import { BedPartyCells } from "components/BedPartyCells";
import { ReservationBoxes } from "./ReservationBoxes";
import { isToday } from "date-fns";
import { AbsenceBox } from "./AbsenceBox";
import { selectAbsenceDates } from "app/dataSlice";

export const DateRow = ({ day }: { day: number }) => {
  const rooms = useAppSelector((state) => state.data.rooms);
  const size = useAppSelector((state) => state.users.size);
  const absDates = useAppSelector((state) => selectAbsenceDates(state.data));
  const absences = useAppSelector((state) => (absDates[day] ?? []).map((a) => state.data.absence[a]));
  const reservations = useAppSelector((state) =>
    (state.data.dates[day] ?? []).map((a) => state.data.reservations[a])
  );
  const countBed = useMemo(
    () => reservations.reduce((acc, cur) => acc + cur.count_bed, 0),
    [reservations]
  );
  const countParty = useMemo(
    () => reservations.reduce((acc, cur) => acc + cur.count_party, 0),
    [reservations]
  );

  switch (size) {
    case Size.XLARGE:
    case Size.LARGE:
      return (
        <tr className={isToday(day) ? styles.today : ''}>
          <td>
            <DateDisplay day={day} />
          </td>
          <BedPartyCells countBed={countBed} countParty={countParty} />
          <td>{absences.map((a) => <AbsenceBox absence={a} key={a.id}/> )}</td>
          {Object.values(rooms).map((room, i) => (
            <td className={i % 2 ? styles.even : styles.odd} key={room.id}>
              <ReservationBoxes day={day} roomId={room.id} />
            </td>
          ))}
        </tr>
      );
    case Size.MEDIUM:
    case Size.SMALL:
    default:
      return (
        <tr className={isToday(day) ? styles.today : ''}>
          {size === Size.MEDIUM && (
            <td>
              <DateDisplay day={day} />
            </td>
          )}
          <BedPartyCells countBed={countBed} countParty={countParty} />
          <td>{absences.map((a) => <AbsenceBox absence={a} key={a.id}/> )}</td>
          <td>
            <div>{size === Size.SMALL && <DateDisplay day={day} />}</div>
            {Object.values(rooms).map((room) => (
              <ReservationBoxes day={day} roomId={room.id} key={room.id} />
            ))}
          </td>
        </tr>
      );
  }
};
