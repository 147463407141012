import { sort } from "app/dataSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { SortableColumn } from "app/types";
import styles from "assets/table.module.scss";
import { Size } from "odoconstants";

export const ReservationsHeader = () => {
  const dispatch = useAppDispatch();
  const rooms = useAppSelector((state) => state.data.rooms);
  const size = useAppSelector((state) => state.users.size);

  const handleSort = (column: SortableColumn) => () => {
    dispatch(sort(column));
  }

  switch (size) {
    case Size.XLARGE:
    case Size.LARGE:
      return (
        <thead>
          <tr>
            <td className={styles.vertical} width="45px">
              Nieobecni
            </td>
            <td className={styles.vertical} width="45px">
              Status
            </td>
            {size === Size.LARGE && <td>
                <span className={styles.sortButton} onClick={handleSort('fromdate')}>Od</span> - 
                <span className={styles.sortButton} onClick={handleSort('todate')}>Do</span>
              </td>}
            {size === Size.XLARGE && (
              <>
                <td><span className={styles.sortButton} onClick={handleSort('fromdate')}>Od</span></td>
                <td><span className={styles.sortButton} onClick={handleSort('todate')}>Do</span></td>
              </>
            )}
            <td><span className={styles.sortButton} onClick={handleSort('client')}>Klient</span></td>
            <td className={styles.vertical} width="45px">
              <span className={styles.sortButton} onClick={handleSort('count_bed')}>Noclegi</span>
            </td>
            <td className={styles.vertical} width="45px">
              <span className={styles.sortButton} onClick={handleSort('count_party')}>Imprezy</span>
            </td>
            <td>
              <span className={styles.sortButton} onClick={handleSort('source')}>Źródło</span>
            </td>
            <td>
              <span className={styles.sortButton} onClick={handleSort('remarks')}>Uwagi</span>
            </td>
            {size === Size.LARGE && <td>Pokoje</td>}
            {size === Size.XLARGE &&
              Object.values(rooms).map((room, i) => (
                <td
                  className={
                    i % 2
                      ? styles.even + " " + styles.vertical
                      : styles.odd + " " + styles.vertical
                  }
                  key={room.id}
                >
                  {room.name}
                </td>
              ))}
          </tr>
        </thead>
      );
    case Size.MEDIUM:
    case Size.SMALL:
    default:
      return (
        <thead>
          <tr>
            <td className={styles.vertical} width="45px">
              Nieobecni
            </td>
            <td><span className={styles.sortButton} onClick={handleSort('fromdate')}>Rezerwacja</span></td>
            <td className={styles.vertical} width="45px">
              <span className={styles.sortButton} onClick={handleSort('count_bed')}>Noclegi</span>
            </td>
            <td className={styles.vertical} width="45px">
              <span className={styles.sortButton} onClick={handleSort('count_party')}>Imprezy</span>
            </td>
            {size === Size.MEDIUM && <td><span className={styles.sortButton} onClick={handleSort('remarks')}>Uwagi</span></td>}
          </tr>
        </thead>
      );
  }
};
