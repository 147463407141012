import { useAppSelector } from "app/hooks";
import styles from "assets/table.module.scss";
import { ModalContext } from "components/Modal/ModalContext";
import { ReservationForm } from "components/ReservationForm/ReservationForm";
import { useContext } from "react";

export const ReservationBox = ({
  reservationId,
  roomId,
  hasConflict,
}: {
  reservationId: number;
  roomId: number;
  hasConflict: boolean;
}) => {
  const modal = useContext(ModalContext);
  const lookup = useAppSelector(
    (state) => state.data.lookup[reservationId + "|" + roomId]
  );
  const reservation = useAppSelector(
    (state) => state.data.reservations[reservationId]
  );
  const editReservation = () => {
    modal?.open({ component: <ReservationForm reservation={reservation}/> });
  }
  const clName = hasConflict ? styles.reservationBox + ' ' + styles.conflict : styles.reservationBox;
  return lookup ? (
    <div className={clName} onClick={editReservation}>
      {lookup?.bed_count ?? "-"} - {reservation.client}
    </div>
  ) : (
    <></>
  );
};
