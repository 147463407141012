import { deleteAbsence, upsertAbsence } from "app/dataSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Absence } from "app/types"
import styles from 'assets/absform.module.scss'
import { CalendarInput } from "components/CalendarInput";
import { ModalContext } from "components/Modal/ModalContext";
import { useConflictFinder } from "components/ReservationsTable/useConflictFinder";
import { add, differenceInCalendarDays, startOfTomorrow } from "date-fns";
import { Size } from "odoconstants";
import { useContext, useId, useState } from "react";

const newAbsence = ():Absence<number> => ({
	fromdate: startOfTomorrow().getTime(),
	todate: add(startOfTomorrow(), {days: 7}).getTime(),
	who: '',
	why: '',
	id: -1,
})

export const AbsenceForm = ({absence}: {absence?: Absence<number>}) => {
	const dispatch = useAppDispatch();
  	const size = useAppSelector((state) => state.users.size);
  	const sizeClass =
    	size === Size.SMALL || size === Size.MEDIUM ? "small" : "big";
	const [title] = useState(
		absence
			? `Edytuj nieobecność ${absence.why}`
			: "Dodaj nieobecność"
		);
	const id = useId();
	const [abs, setAbs] = useState(absence ? absence : newAbsence());
	const [allowDelete, setAllowDelete] = useState(false);
	const modal = useContext(ModalContext);
	const [delta, setDelta] = useState(
		differenceInCalendarDays(abs.todate, abs.fromdate)
	  );
	const getConflicts = useConflictFinder(abs.fromdate, abs.todate, -1);
	const reservations = useAppSelector((state) => state.data.reservations);
	const reservationsDuringAbsence = getConflicts.forTimePeriod();

	const handleDateChange = (param: "fromdate" | "todate") => (val: number) => {
		const start = param === "fromdate" ? val : abs.fromdate;
		const end = param === "todate" ? val : abs.todate;
		if (end > start) {
		  setAbs((a) => ({ ...a, [param]: val }));
		  setDelta(differenceInCalendarDays(end, start));
		  return;
		}
		if (param === "fromdate") {
		  const newEndDate = add(val, { days: delta }).getTime();
		  setAbs((a) => ({ ...a, [param]: val, todate: newEndDate }));
		}
		if (param === "todate") {
		  const newStartDate = add(val, { days: -delta }).getTime();
		  setAbs((a) => ({ ...a, [param]: val, fromdate: newStartDate }));
		}
	  };

	const handleRadio =
    (value: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      setAbs((a) => ({ ...a, who: value }));
    };

	const remove = () => {
		if (!allowDelete) {
		  setAllowDelete(true);
		} else {
		  dispatch(deleteAbsence(abs.id));
		  modal?.close();
		}
	  };

	const save = () => {
		dispatch(upsertAbsence(abs));
		modal?.close();
	 };
	
	return (
		<>
		<div className={styles.formHeader}>{title}</div>
		<div className={styles.formBody + " " + styles[sizeClass]}>
			<div className={styles.formContainer}>
				<div>
					<label htmlFor={id + "fromdate"}>Od</label>
					<CalendarInput
					id={id + "fromdate"}
					value={abs.fromdate}
					onChange={handleDateChange("fromdate")}
					/>
				</div>
				<div>
					<label htmlFor={id + "todate"}>Do</label>
					<CalendarInput
					id={id + "todate"}
					value={abs.todate}
					onChange={handleDateChange("todate")}
					/>
				</div>
				<div>
					<span className={styles.label}>Kto będzie nieobecny</span>
					<input
						type="radio"
						id={id + "abs_jurek"}
						name="who"
						checked={abs.who.toLowerCase() === "jurek"}
						onChange={handleRadio("Jurek")}
						/>
					<label htmlFor={id + "abs_jurek"}>Jurek</label>
					<input
						type="radio"
						id={id + "abs_marek"}
						name="who"
						checked={abs.who.toLowerCase() === "marek"}
						onChange={handleRadio("Marek")}
						/>
					<label htmlFor={id + "abs_marek"}>Marek</label>
				</div>
				<div>
					<span className={styles.label}></span>
					<input
					type="text"
					id={id + "who"}
					value={abs.who}
					onChange={(evt) =>
						setAbs((a) => ({ ...a, who: evt.target.value }))
					}
					/>
				</div>
				<div>
					<label htmlFor={id + "why"}>Dlaczego</label>
					<textarea
						className={styles.remarksArea}
						id={id + "why"}
						onChange={(evt) =>
							setAbs((a) => ({ ...a, why: evt.target.value }))
						}
						value={abs.why}
					/>
				</div>
				<div>
					{reservationsDuringAbsence.length > 0 && <>
						<div>Rezerwacje w tym czasie</div>
						<div className={styles.reservations}>{reservationsDuringAbsence.map((r) => <div key={r}>{reservations[r].client}</div> )}</div>
					</>}
				</div>
				<div>
					{absence && <button className={styles.deleteButton} onClick={remove}>
						{!allowDelete ? 'Usuń' : 'Ale na pewno usunąć?'}
					</button>}
					<button className={styles.applyButton} onClick={save}>
						Zapisz Nieobecność
					</button>
				</div>
			</div>
		</div>
		</>
	)
}