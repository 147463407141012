import { useContext } from "react";
import { ModalContext } from "./ModalContext";
import styles from "./modal.module.css";

export const Modal = () => {
  const modalContext = useContext(ModalContext);

  return (
    <>
      {modalContext && modalContext.state.isOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalWindow}>
            <button
              className={styles.modalCloseButton}
              onClick={modalContext?.close}
            >
              X
            </button>
            {modalContext?.state.component ?? <></>}
          </div>
        </div>
      )}
    </>
  );
};
