import { SetStateAction } from "react";
import { CalView, months } from "./utils";
import styles from "./calendar.module.scss";
import { add, format, set } from "date-fns";

type YearProps = {
  currentDate: number;
  setCurrentDate: React.Dispatch<SetStateAction<number>>;
  changeView: (v: CalView, evt: React.MouseEvent) => void;
};

export const Year = ({
  currentDate,
  setCurrentDate,
  changeView,
}: YearProps) => {
  const handleClick = (month: number) => (evt: React.MouseEvent) => {
    setCurrentDate(set(currentDate, { month }).getTime());
    changeView(CalView.MONTH, evt);
  };

  const swipeYear = (amount: -1 | 1) => () => {
    setCurrentDate((d) => add(d, { years: amount }).getTime());
  };

  return (
    <>
      <div className={styles.calendarHeader}>
        <div onClick={swipeYear(-1)} className={styles.arrow}>
          &lt;
        </div>
        <div className={styles.headerInner}>
          <div
            onClick={(evt) => {
              changeView(CalView.DECADE, evt);
            }}
          >
            {format(currentDate, "yyyy")}
          </div>
        </div>
        <div onClick={swipeYear(-1)} className={styles.arrow}>
          &gt;
        </div>
      </div>
      <div className={styles.decadeView}>
        {months.map((m, i) => (
          <div onClick={handleClick(i)} key={m}>
            {m}
          </div>
        ))}
      </div>
    </>
  );
};
