import { useAppSelector } from "app/hooks";
import { Size } from "odoconstants";
import styles from "assets/table.module.scss";

export const RoomsHeader = () => {
  const rooms = useAppSelector((state) => state.data.rooms);
  const size = useAppSelector((state) => state.users.size);
  switch (size) {
    case Size.XLARGE:
    case Size.LARGE:
      return (
        <thead>
          <tr>
            <td>Data</td>
            <td className={styles.vertical} width="45px">
              Noclegi
            </td>
            <td className={styles.vertical} width="45px">
              Imprezy
            </td>
            <td className={styles.vertical} width="45px">
              Nieobecni
            </td>
            {Object.values(rooms).map((room, i) => (
              <td
                className={
                  i % 2
                    ? styles.even + " " + styles.vertical
                    : styles.odd + " " + styles.vertical
                }
                key={room.id}
              >
                {room.name}
              </td>
            ))}
          </tr>
        </thead>
      );
    case Size.MEDIUM:
    case Size.SMALL:
    default:
      return (
        <thead>
          <tr>
            {size === Size.MEDIUM && <td>Data</td>}
            <td className={styles.vertical} width="45px">
              Noclegi
            </td>
            <td className={styles.vertical} width="45px">
              Imprezy
            </td>
            <td className={styles.vertical} width="45px">
              Nieobecni
            </td>
            <td>Pokoje</td>
          </tr>
        </thead>
      );
  }
};
