import styles from "./toasters.module.scss";
import { Toaster } from "./Toaster";
import { useAppSelector } from "app/hooks";

export const ToasterContainer = () => {
  const toasters = useAppSelector((state) => state.users.toasters);

  return (
    <div className={styles.toasterContainer}>
      {Object.keys(toasters).map((id) => (
        <Toaster
          {...toasters[id as unknown as number]}
          toasterId={id as unknown as number}
          key={id}
        />
      ))}
    </div>
  );
};
