import { selectAbsenceDates } from "app/dataSlice";
import { useAppSelector } from "app/hooks";
import { add, differenceInDays } from "date-fns";
import { useCallback, useMemo } from "react";

export const useConflictFinder = (fromdate: number, todate: number, resId: number) => {
	const {rooms, dates, lookup, absence} = useAppSelector((state) => state.data);
	const absDates = useAppSelector((state) => selectAbsenceDates(state.data));

	const absencesDuringReservation = useMemo(() => {
		const d = differenceInDays(todate, fromdate);
		const abs: number[] = [];
		for (let i = 0; i < d; i++) {
			const day = add(fromdate, { days: i }).getTime();
			const absencesOnDay = absDates[day] ?? [];
			abs.push(...absencesOnDay);
		}
		return abs.filter((r, i, c) => c.indexOf(r) === i).map((k) => absence[k]);
	}, [fromdate, todate, absDates, absence]);

	const conflictingReservations = useMemo(() => {
		const d = differenceInDays(todate, fromdate);
		const rs: number[] = [];
		for (let i = 0; i < d; i++) {
			const day = add(fromdate, { days: i }).getTime();
			const reservationsOnDay = dates[day] ?? [];
			rs.push(...reservationsOnDay);
		}
		return rs.filter((r, i, c) => c.indexOf(r) === i);
	}, [fromdate, todate, dates]);

	const forRoom = useCallback((roomId: number, roomTaken?: boolean) => {
		return conflictingReservations.reduce((acc, cur) => {
			if (cur !== resId && lookup[cur + '|' + roomId] !== undefined && (lookup[resId + '|' + roomId] !== undefined || roomTaken)) {
				acc.push(cur);
			}
			return acc;
		}, [] as number[]);
	}, [conflictingReservations, resId, lookup]);

	const forAllRooms = useCallback(() => {
		return Object.keys(rooms).reduce((acc, cur) => ({
			...acc,
			[cur]: forRoom(parseInt(cur), false)
		}), {} as Record<number, number[]>)
	}, [rooms, forRoom])

	const forTimePeriod = useCallback(() => conflictingReservations, [conflictingReservations])
	const absences = useCallback(() => absencesDuringReservation, [absencesDuringReservation])

	return {forRoom, forAllRooms, forTimePeriod, absences};
}