import { useAppSelector } from 'app/hooks'
import styles from 'assets/conflict.module.scss'
import { useConflictFinder } from 'components/ReservationsTable/useConflictFinder';
import { format } from 'date-fns';

export const ConflictWindow = ({resId, conflicts}: {resId: number, conflicts: number[]}) => {
	const {reservations, rooms} = useAppSelector((state) => state.data);
	const reservation = reservations[resId];
	const getConflicts = useConflictFinder(reservation.fromdate, reservation.todate, reservation.id);
	const conflictsByRoom = getConflicts.forAllRooms();

	return <div>
		<div className={styles.formHeader}>Konflikty Rezerwacji "{reservation.client}"</div>
		<div className={styles.formBody}>
			<h2>Rezerwacja "{reservation.client}" ({format(reservation.fromdate, 'yyyy-MM-dd')} - {format(reservation.todate, 'yyyy-MM-dd')}) koliduje z następującymi rezerwacjami:</h2>
			<ul>
				{Object.values(rooms).map((room) =>
					(conflictsByRoom[room.id].length === 0 ? null
					: <li key={room.id}>pokój {room.name}
						<ul>
							{conflictsByRoom[room.id].map((rid) => 
							<li key={room.id + '-' + rid}>
								Rezerwacja "{reservations[rid].client}" ({format(reservations[rid].fromdate, 'yyyy-MM-dd')} - {format(reservations[rid].todate, 'yyyy-MM-dd')})
							</li> )}
						</ul>
					</li> )
					)}
			</ul>
		</div>
	</div>
}