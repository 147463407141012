import { Absence } from "app/types";
import styles from 'assets/table.module.scss';
import { AbsenceForm } from "components/AbsenceForm/AbsenceForm";
import { ModalContext } from "components/Modal/ModalContext";
import { useContext } from "react";

export const AbsenceBox = ({absence}: {absence: Absence<number>})  => {
	const modal = useContext(ModalContext);
	const editAbsence = () => {
		modal?.open({ component: <AbsenceForm absence={absence}/> });
	  }
	
	return <div className={styles.absenceBox} onClick={editAbsence}>{absence.who[0]}</div>
}