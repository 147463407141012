import { useAppSelector } from "app/hooks";
import styles from "assets/table.module.scss";
import { ReservationsHeader } from "./ReservationsHeader";
import { ReservationsRow } from "./ReservationsRow";

export const ReservationsTable = () => {
  const reservations = useAppSelector((state) => state.data.reservations);
  const resIds = useAppSelector((state) => state.data.resIds);

  return (
    <table className={styles.table}>
      <ReservationsHeader />
      <tbody>
        {resIds.map((key, i) => (
          <ReservationsRow reservation={reservations[key]} key={key} />
        ))}
      </tbody>
    </table>
  );
};
