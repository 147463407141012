import { format, isValid } from "date-fns";
import { DATE_FORMAT } from "odoconstants";
import { useCallback, useEffect, useRef, useState } from "react";
import { Calendar } from "./Calendar/Calendar";

type CalendarInputProps = {
  id: string;
  value: number;
  onChange: (val: number) => void;
};

export const CalendarInput = ({ id, value, onChange }: CalendarInputProps) => {
  const [val, setVal] = useState(format(value, DATE_FORMAT));
  const [showCalendar, setShowCalendar] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setVal(format(value, DATE_FORMAT)), [value]);

  const endEdit = useCallback(
    (newVal?: number) => {
      const inputDate = new Date(newVal ?? inputRef.current?.value ?? "-");
      if (isValid(inputDate)) {
        onChange(inputDate.getTime());
      } else {
        setVal(format(value, DATE_FORMAT));
      }
    },
    [inputRef, onChange, value]
  );

  const handleClickOutside = useCallback(
    (evt?: MouseEvent, newVal?: number) => {
      setShowCalendar(false);
      window.removeEventListener("click", handleClickOutside);
      endEdit(newVal);
    },
    [endEdit]
  );

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setVal(evt.target.value);

  const handleFocus = () => {
    setShowCalendar(true);
    window.addEventListener("click", handleClickOutside);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter" || evt.key === "Escape") {
      (evt.target as HTMLInputElement).blur();
      handleClickOutside();
    }
  };

  const handleDateClicked = (v: number) => {
    setVal(format(v, DATE_FORMAT));
    handleClickOutside(undefined, v);
  };

  return (
    <div onClick={(evt) => evt.stopPropagation()}>
      <input
        type="text"
        id={id}
        value={val}
        ref={inputRef}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
      />
      {showCalendar && (
        <Calendar initDate={value} onDateClicked={handleDateClicked} />
      )}
    </div>
  );
};
